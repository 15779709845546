import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';

export default function Pricing() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-gray-100 text-black min-h-screen">
      <Navbar />
      <main className="container mx-auto py-12 px-4">
        <section className="text-center mb-16">
          <h1 className="text-5xl font-extrabold mb-8 text-gray-800">Our Pricing Plans</h1>
          <p className="text-lg text-gray-600 mb-8">Choose the plan that best fits your needs and start automating your tasks with B.E.T.H.</p>
          <div className="flex flex-col md:flex-row items-stretch md:space-x-8 justify-center">
            {/* Basic Plan */}
            <div className="flex-grow bg-white p-6 rounded-lg shadow-lg mb-8 md:mb-0 max-w-full md:max-w-sm flex flex-col justify-between border border-gray-200 transform transition hover:scale-105">
              <div>
                <h2 className="text-3xl font-bold mb-4 text-blue-500">Basic Plan</h2>
                <p className="text-2xl font-semibold mb-6 text-gray-800">Free/lifetime</p>
                <ul className="text-left mb-8 text-gray-700">
                  <li className="mb-3 flex items-center"><span className="mr-2">✔️</span>Includes 5,000 tokens worth of B.E.T.H. AI Co-Pilot responses</li>
                  <li className="mb-3 flex items-center"><span className="mr-2">✔️</span>Provides basic, straightforward answers</li>
                  <li className="mb-3 flex items-center"><span className="mr-2">✔️</span>Basic analytics</li>
                </ul>
              </div>
              <Link to="/signup" className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 self-center font-semibold transition">Get Started →</Link>
            </div>

            {/* Premium Plan */}
            <div className="flex-grow bg-white p-6 rounded-lg shadow-lg max-w-full md:max-w-sm flex flex-col justify-between border border-gray-200 transform transition hover:scale-105">
              <div>
                <h2 className="text-3xl font-bold mb-4 text-green-500">Premium Plan</h2>
                <div className="text-2xl font-semibold mb-6 flex justify-center items-center">        
                    <span>$16.99/month</span>
                </div>
                <ul className="text-left mb-8 text-gray-700">
                  <li className="mb-3 flex items-center"><span className="mr-2">✔️</span>Unlimited Tokens</li>
                  <li className="mb-3 flex items-center"><span className="mr-2">✔️</span>File Analysis</li>
                  <li className="mb-3 flex items-center"><span className="mr-2">✔️</span>Delivers detailed and sophisticated answers</li>
                  <li className="mb-3 flex items-center"><span className="mr-2">✔️</span>Access to dedicated customer support with priority response times</li>
                </ul>
              </div>

              <Link to="/signup" className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 self-center font-semibold transition">Get Started →</Link>
            </div>
          </div>
          <p className="sm:w-auto mt-10 text-gray-700 ">1 token = ~4 characters in English, Input and Output</p>

          {/* Comparison Table */}
          <section className="mt-16">
            <hr className="my-8 border-t-2 border-gray-300" />
            <h2 className="text-4xl font-bold mb-8">Feature Comparison</h2>
            <div className="overflow-x-auto">
              <table className="table-auto w-full border-collapse border border-gray-300 text-gray-800">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 px-4 py-2 text-center">Feature</th>
                    <th className="border border-gray-300 px-4 py-2 text-center">Basic Plan</th>
                    <th className="border border-gray-300 px-4 py-2 text-center">Premium Plan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">Chat Tokens</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">5,000</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">Unlimited</td>
                  </tr>
              
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">GPT-4o mini</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">✔️</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">✔️</td>
                  </tr>
                  <tr className="bg-gray-50">
                    <td className="border border-gray-300 px-4 py-2">GPT 4o</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">✖️</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">✔️</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">Advanced Analysis</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">✖️</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">✔️</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <div className="mt-16 text-center">
          <hr className="my-8 border-t-2 border-gray-300" />
          <h2 className="text-4xl font-bold mb-4">Give B.E.T.H a try</h2>
          <div className="flex justify-center">
              <p className="mb-8 text-lg text-gray-700 w-3/4 p-4">
                  Sign up today and experience the future of financial assistance. Our AI-driven platform takes the complexity out of accounting, tax, and audit inquiries, so you can focus on what truly matters.
              </p>
          </div>
          <Link to="/signup" className="bg-green-500 text-white px-8 py-4 rounded-lg hover:bg-green-600 font-semibold text-xl transition">
              Get Started →
          </Link>
      </div>


          <section className="mt-16 bg-gray-50 p-8 rounded-lg shadow-lg">
            <hr className="my-8 border-t-2 border-gray-300" />
            <h2 className="text-4xl font-bold mb-8 text-center">Frequently Asked Questions</h2>
            <div className="mx-5 text-left text-gray-800 text-lg">
              {[
                {
                  question: 'What is B.E.T.H?',
                  answer: 'B.E.T.H is an AI Co-Pilot designed to assist you with various tasks by providing intelligent and detailed responses.'
                },
                {
                  question: 'How do I start a conversation with the chatbot?',
                  answer: 'To start a conversation, simply type your question or message in the chat box and press "Enter." The chatbot will respond to your query in real-time. You can ask about a variety of topics, and the chatbot is designed to understand and reply to most common questions.'
                },
                {
                  question: 'What are the benefits of the Premium Plan?',
                  answer: 'The Premium Plan offers unlimited tokens, file analysis, detailed and sophisticated answers, and priority customer support.'
                },
                {
                  question: 'Is my data safe when using the chatbot?',
                  answer: 'Yes, your data is safe when using the chatbot. We use industry-standard encryption and data protection practices to ensure your information is secure.'
                },
                {
                  question: 'Is there a free trial for the Premium Plan?',
                  answer: 'Currently, we do not offer a free trial for the Premium Plan, but you can start with the Basic Plan to get a feel for the services.'
                }
              ].map((item, index) => (
                <div key={index} className="mb-6">
                  <h3
                    onClick={() => toggleAccordion(index)}
                    className="text-2xl font-semibold mb-2 cursor-pointer flex justify-between items-center"
                  >
                    {item.question}
                    <span className={`transform transition-transform ${activeIndex === index ? 'rotate-180' : ''}`}>
                      ▼
                    </span>
                  </h3>
                  {activeIndex === index && (
                    <p className="text-lg mt-2">{item.answer}</p>
                  )}
                </div>
              ))}
            </div>
          </section>
        </section>
      </main>
      <Footer/>

    </div>
  );
}
