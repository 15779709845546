import React from "react";
import Modal from "react-modal";

export default function Profile({ modalOpen, closeModal, logout, user}) {
   
    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            contentLabel="Profile"
            className="fixed inset-0 flex items-center justify-center z-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
        >
            <main className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 relative">
                <button
                    onClick={closeModal}
                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
                    style={{ marginTop: '16px', marginRight: '16px' }}
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                <section className="flex flex-col items-center bg-gradient-to-r from-green-300 to-green-400 py-4 rounded-t-lg shadow-md">
                    <div className="flex items-center justify-center w-16 h-16 mb-2 rounded-full bg-gray-200">
                        <img src="sss.jpeg" alt="Profile" className="rounded-full border-2" />
                    </div>
                    <div className="bg-white w-full rounded flex flex-col items-center py-2">
                        <p className="font-bold text-2xl text-green-500">Jonathan Doe</p>
                        <p className="text-md text-green-600">Free User</p>
                    </div>
                </section>

                <section className="p-6 space-y-4">
                    <div className="border shadow-md p-4">
                        <h3 className="text-xl font-bold mb-2">User Information</h3>
                        <p className="mb-1"><span className="font-semibold">Email:</span> john.doe@example.com</p>
                        <p><span className="font-semibold">Joined:</span> January 1, 2023</p>
                    </div>

                    <div className="border shadow-md p-4">
                        <h3 className="text-xl font-bold mb-2">Plan Details</h3>
                        <p className="mb-1"><span className="font-semibold">Current Plan:</span> Free</p>
                        <p><span className="font-semibold">Next Billing Date:</span> N/A</p>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <button className="bg-green-400 hover:bg-green-500 text-white font-bold mt-2 py-2 px-4 rounded shadow-lg">
                            Change Password
                        </button>
                        <button onClick={logout} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded shadow-lg">
                            Logout
                        </button>
                    </div>
                </section>
            </main>
        </Modal>
    );
}
