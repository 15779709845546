// src/MicrosoftAuthProvider.js
import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalConfig = {
  auth: {
    clientId: "8f54b43c-4227-4087-a4fe-3319ded89cb1", // Replace with your Azure AD client ID
    authority: "https://login.microsoftonline.com/0990a6bc-1393-4c7c-b0f8-245e71d0f4a5", // Replace with your tenant ID
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const MicrosoftAuthProvider = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      {children}
    </MsalProvider>
  );
};
