import React from 'react';
import Navbar from '../../components/NavBar/NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export default function AboutUs() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Navbar />
      <section className="w-full h-72 bg-center bg-cover" style={{ backgroundImage: "url('pic3.jpg')" }}>
        <div className="flex items-center h-full w-full bg-black bg-opacity-50">
          <div className="w-full p-6 text-center bg-black bg-opacity-20">
            <h1 className="text-white text-4xl sm:text-5xl font-bold mb-4">Welcome to Babylon</h1>
          </div>
        </div>
      </section>

      <main className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <section className="flex flex-col md:flex-row items-center text-center md:text-left mb-12">
          <div className="w-full md:w-1/2 p-6">
            <h1 className="text-3xl md:text-4xl font-bold mb-6" style={{ color: '#66B539' }}>About Us</h1>
            <p className="text-base md:text-lg leading-7 md:leading-8 mb-6 text-gray-700">
              At Babylon2K, we believe in empowering businesses to reach their full potential through accessible, top-notch support. We understand how it is to experience a least priority from your service providers and/or inaccuracies in reporting. We also know how to turn setbacks into opportunities to create game-changing solutions.
              <br /><br />
              We know because that’s how Babylon2K was born as an online accounting-based ‘work order system’ that serves businesses like yours.
            </p>
          </div>
          <div className="w-full md:w-1/2 p-6">
            <p className="text-base md:text-lg leading-7 md:leading-8 mb-6 text-gray-700">
              Our mission is simple: to help your business save time, save on costs, and reduce financial risks. We’ve come a long way since 2021, starting as a few partners with a big vision. We’ve focused on growing our reach, establishing a strong presence in cities like Panay Island, Cebu, and Davao, and building partnerships that strengthen our credibility and expertise.
            </p>
          </div>
        </section>

        <section className="bg-white p-6 sm:p-8 rounded-lg shadow-md mb-12">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-center" style={{ color: '#66B539' }}>Our Mission</h2>
          <p className="text-center sm:text-lg leading-7 text-gray-700">
            Babylon2K’s mission is to democratize the accounting profession and business support services. 
            We are dedicated to eradicating the injustices that create long waits, high costs, and centralized development. 
            By leveraging technology and innovation, we offer a more equitable, streamlined, and cost-effective approach to 
            doing business. Our services are designed to empower professionals in every corner of the world, 
            facilitating decentralized progress and ensuring that every business decision contributes to a broader 
            economic development.
          </p>
        </section>

        <section className="bg-white p-6 sm:p-8 rounded-lg shadow-md mb-12">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-center" style={{ color: '#66B539' }}>Our Vision</h2>
          <p className="text-center sm:text-lg leading-7 text-gray-700">
            In a world where the flow of commerce is often hindered by inequality, inefficiency, and exclusivity, 
            Babylon2K stands as a beacon of progress and equal opportunity. We envision a future where every entrepreneur or 
            organization, irrespective of their location, has access to fair, efficient, and comprehensive business support. 
            Our digital platform dismantles the barriers of geography and bureaucracy, fostering an environment 
            where the success of each accountant and entrepreneur amplifies the collective strength of the global economy.
          </p>
        </section>

        <section className="relative p-6 sm:p-9 rounded-lg shadow-md mb-12 text-center bg-green-200">
          <h1 className="text-4xl sm:text-5xl font-bold mb-6 text-black">Join Our Group</h1>
          <p className="text-lg sm:text-xl text-gray-800 mb-6">
            Get involved in our community and be part of the change!
          </p>
          <a 
            href='https://www.facebook.com/groups/taxsatori/?ref=share&mibextid=JOZb8W'
            target='_blank'
            className='bg-green-500 text-white mt-4 px-4 py-3 rounded-md'
            rel='noopener noreferrer'
          >
            Join Now
          </a>
        </section>

        <section className="bg-white p-6 sm:p-8 rounded-lg shadow-md mt-12">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-center" style={{ color: '#66B539' }}>Contact Us</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="col-span-1 text-left w-auto">
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">Address</h3>
              <p className="mb-4">Makati, Philippines</p>
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">Email</h3>
              <p>ask@babylon2k.org</p>
            </div>
            
            <div className="col-span-1 text-center sm:text-left w-auto">
    
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">Follow Us</h3>
              <ul className="flex justify-center space-x-4 sm:justify-start">
                <li>
                  <a href="https://www.facebook.com/babyloneth" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="lg" />
                  </a>
                </li>
                <li>
                  <a href="https://x.com/uhy_mac" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} size="lg" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/babylon2k" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
                  </a>
                </li>
              </ul>
            </div>
            
            <div className="col-span-2">
              <div className="h-full">
                <iframe 
                  className="w-full h-72 sm:h-96 rounded-lg shadow-lg" 
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15447.45427807874!2d121.013302!3d14.5497934!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c91377a5394b%3A0x46b25c2900f79882!2sBabylon!5e0!3m2!1sen!2sph!4v1718680761392!5m2!1sen!2sph" 
                  allowFullScreen="" 
                  loading="lazy" 
                  referrerPolicy="no-referrer-when-downgrade">
                </iframe>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
