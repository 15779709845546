import React from 'react';

const PredefinedQuestions = ({ predefinedQuestions, handlePredefinedQuestionClick }) => {
    return (
        <div className="flex flex-col justify-center my-8 sm:flex-row">
            <div className="flex flex-wrap justify-center items-stretch">
                {predefinedQuestions.slice(0, 6).map((predefined, index) => (
                    <button
                        key={index}
                        className="mr-0 sm:mr-2 mt-2 p-2 bg-gray-200 hover:bg-gray-300 rounded shadow w-full sm:w-1/3 text-xs"
                        onClick={() => handlePredefinedQuestionClick(predefined.question)} // Only pass the question
                    >
                        {predefined.question}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default PredefinedQuestions;
