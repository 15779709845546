import React, { useState } from "react";
import Navbar from "../../components/NavBar/NavBar";

export default function Sources() {
    const items = [
        {
          src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/Partner%20Logos/bir1.png",
          alt: "bir",
          label: "Bureau of Internal Revenue",
          description: "The Bureau of Internal Revenue (BIR) is responsible for the collection of taxes and enforcement of tax laws in the Philippines.",
        },
        {
          src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/SEC.png",
          alt: "sec",
          label: "Securities and Exchange Commission",
          description: "The SEC regulates and supervises the securities and exchange industry in the Philippines to ensure transparency and protect investors.",
        },
        {
          src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/PEZA.png",
          alt: "peza",
          label: "Philippine Economic Zone Authority",
          description: "PEZA promotes investments in export-oriented manufacturing and service industries by providing incentives and support to businesses operating in economic zones.",
        },
        {
          src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/NEA.png",
          alt: "nea",
          label: "National Electrification Administration",
          description: "NEA provides financial and technical support to electric cooperatives to ensure the electrification of rural areas in the Philippines.",
        },
        {
          src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/CDA.png",
          alt: "cda",
          label: "Cooperative Development Authority",
          description: "The CDA is responsible for the registration, regulation, and development of cooperatives in the Philippines, aiming to promote their growth and sustainability.",
        },
        {
          src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/BOC.png",
          alt: "boc",
          label: "Bureau of Customs",
          description: "The Bureau of Customs (BOC) is tasked with collecting customs duties, safeguarding borders, and regulating the entry and exit of goods in the Philippines.",
        },
        {
          src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/Partner%20Logos/bsp.png",
          alt: "bsp",
          label: "Bangko Sentral ng Pilipinas",
          description: "The Bangko Sentral ng Pilipinas (BSP) is the central bank of the Philippines, responsible for monetary policy, financial stability, and currency issuance.",
        },
        {
          src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/Partner%20Logos/pagibig.png",
          alt: "pag-ibig",
          label: "Pag-IBIG",
          description: "Pag-IBIG Fund provides affordable housing loans and savings programs to help Filipino workers achieve their dream of home ownership.",
        },
        {
          src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/Philhealth.png",
          alt: "philhealth",
          label: "PhilHealth",
          description: "PhilHealth is the national health insurance program that provides financial assistance to Filipinos for healthcare services and hospitalization.",
        },
        {
          src: "https://bethaibucket.s3.amazonaws.com/images/Partner+Logos/SSS.png",
          alt: "sss",
          label: "Social Security System",
          description: "The Social Security System (SSS) provides social security protection to workers in the private sector through benefits for sickness, disability, retirement, and death.",
        },
        {
          src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/Partner%20Logos/dti.png",
          alt: "dti",
          label: "Department of Trade and Industry",
          description: "The Department of Trade and Industry (DTI) is responsible for promoting trade and industry development, supporting entrepreneurship, and enhancing the competitiveness of Philippine businesses.",
        },
        {
          src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/Partner%20Logos/erc.png",
          alt: "erc",
          label: "Energy Regulatory Commission",
          description: "The Energy Regulatory Commission (ERC) regulates the electricity sector in the Philippines, ensuring fair, transparent, and reliable energy services.",
        },
        {
          src: "https://bethaibucket.s3.us-east-1.amazonaws.com/images/Partner%20Logos/pdic.png",
          alt: "pdic",
          label: "Philippine Deposit Insurance Corporation",
          description: "The Philippine Deposit Insurance Corporation (PDIC) provides insurance coverage for deposits and aims to promote public confidence in the banking system.",
        },
    ];
    

  const [flippedIndex, setFlippedIndex] = useState(null);

  return (
      <>
        <Navbar />
        <section className="container mx-auto p-4">
          <h1 className="text-center font-bold text-3xl my-4">Our Sources</h1>
          <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">
            We rely on trusted organizations and agencies to provide accurate and
            up-to-date information. Below are some of the key sources that help
            us deliver reliable data and insights.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {items.map((item, index) => (
              <div
                key={index}
                className="relative w-full h-60 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-500 hover:scale-105"
                style={{ perspective: "500px" }}
                onMouseEnter={() => setFlippedIndex(index)}
                onMouseLeave={() => setFlippedIndex(null)}
              >
                <div
                  className={`relative w-full h-full transform-style-3d transition-transform duration-500 ${
                    flippedIndex === index ? "rotate-y-180" : ""
                  }`}
                >
                  <div className="absolute w-full h-full flex flex-col justify-center items-center bg-white p-4 backface-hidden">
                    <img
                      src={item.src}
                      alt={item.alt}
                      className="w-[135px] h-[100px] mb-4"
                      />
                    <p className="text-center text-sm font-semibold">
                      {item.label}
                    </p>
                  </div>
                  <div className="absolute w-full h-full flex flex-col justify-center items-center bg-gray-200 p-4 transform rotate-y-180 backface-hidden">
                    <p className="text-center text-sm">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </>
    );
  }
