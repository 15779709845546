import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../utils/api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function SideBar({
  token,
  threadId,
  setThreadId,
  setMessages,
  logout,
  sidebarVisible,
  setSidebarVisible,
  openModal,
}) {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [renamingThreadId, setRenamingThreadId] = useState(null);
  const [newThreadName, setNewThreadName] = useState("");
  const modalRef = useRef(null);
  const navigate = useNavigate();

  const userLevel = JSON.parse(sessionStorage.getItem("userLevel"));

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await api.get("/chat/history/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setHistory(response.data);
      } catch (error) {
        setError("Error getting history");
        console.error("Error getting history:", error);
      }
    };

    fetchHistory();
  }, [token, refresh]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const handleThreadClick = async (threadId) => {
    if (isFetching) return;

    // Check if messages for this thread are already in local storage
    // const storedMessages = localStorage.getItem(`messages_${threadId}`);
    // if (storedMessages) {
    //   setMessages(JSON.parse(storedMessages));
    //   setThreadId(threadId);
    //   sessionStorage.setItem('threadId', threadId);
    //   return;
    // }

    // If not in local storage, fetch from server
    setThreadId(threadId);
    sessionStorage.setItem("threadId", threadId);
    setMessages([]);
    setIsFetching(true);

    try {
      const response = await api.get(`/chat/history/thread/${threadId}`);
      setMessages(response.data);

      // Save fetched messages to local storage
      // localStorage.setItem(`messages_${threadId}`, JSON.stringify(response.data));
    } catch (error) {
      setError("Error getting history");
      console.error("Error getting thread:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleNewSession = async () => {
    if (isFetching) return;
    setIsFetching(true);
    try {
      const response = await api.get(`/chat/new/thread`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setThreadId(response.data.thread_id);
      setMessages([]);
      setRefresh(!refresh);
    } catch (error) {
      setError("Error creating new session");
      console.error("Error creating new session:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleRename = (threadId) => {
    setRenamingThreadId(threadId);
    const thread = history.find((item) => item.thread_id === threadId);
    setNewThreadName(thread ? thread.name : "");
  };

  const submitRename = async (threadId) => {
    try {
      await api.post(
        `/chat/thread/rename?thread_id=${threadId}&new_name=${newThreadName}`
      );
      setRenamingThreadId(null);
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error renaming thread:", error);
    }
  };

  const handleDelete = async (threadId) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this thread?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await api.delete(`/chat/history/thread/delete/${threadId}`);
              sessionStorage.clear();
              window.location.reload();
            } catch (error) {
              console.error("Error deleting thread:", error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handlePin = async (threadId) => {
    setDropdownOpen(null);
  };

  const handleButtonClick = (e, action) => {
    e.stopPropagation();
    e.preventDefault();
    action();
    setDropdownOpen(null);
  };

  const getLinkClasses = (id) => {
    return id === threadId ? "font-bold" : "";
  };

  return (
    <div className="flex flex-col flex-grow border-r-2">
      {/* Header Section */}
      <div className="flex justify-between items-center p-4 bg-gray-200 border-b border-gray-300 shadow-md">
        <button
          className="text-gray-600 hover:text-gray-800 focus:outline-none"
          onClick={() => setSidebarVisible(!sidebarVisible)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {sidebarVisible ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>
      </div>

      <div className="p-4 bg-gray-200 border-b border-gray-300">
        <button
          onClick={handleNewSession}
          className="bg-green-500 text-white rounded-lg p-2 mb-2 w-full hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors duration-200"
          disabled={isFetching}
        >
          New Chat
        </button>
      </div>

      <ul className="flex-grow overflow-y-auto p-4 space-y-2 max-h-[calc(100vh-200px)]">
        {history.map((item) => (
          <li
            key={item.thread_id}
            className={`flex justify-between items-center p-3 rounded-lg cursor-pointer transition duration-200 ${
              item.thread_id === threadId
                ? "bg-gray-200"
                : "bg-white hover:bg-gray-100"
            }`}
            onClick={() => handleThreadClick(item.thread_id)}
          >
            {renamingThreadId === item.thread_id ? (
              <input
                type="text"
                value={newThreadName}
                onChange={(e) => setNewThreadName(e.target.value)}
                onBlur={() => submitRename(item.thread_id)}
                onKeyDown={(e) =>
                  e.key === "Enter" && submitRename(item.thread_id)
                }
                className="border border-gray-300 p-2 rounded-lg w-full"
                autoFocus
              />
            ) : (
              <p className={getLinkClasses(item.thread_id)}>
                {item.name || `Name: ${item.thread_id}`}
              </p>
            )}

            <div className="relative">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setDropdownOpen(item.thread_id);
                  setRenamingThreadId(null);
                }}
                className="focus:outline-none hover:bg-gray-100 p-2 rounded-full"
              >
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="text-gray-600 hover:text-gray-800"
                />
              </button>

              {dropdownOpen === item.thread_id && (
                <div
                  className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-50"
                  ref={modalRef}
                >
                  <button
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={(e) =>
                      handleButtonClick(e, () => handlePin(item.thread_id))
                    }
                  >
                    Pin
                  </button>

                  <button
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    onClick={(e) => {
                      handleButtonClick(e, () => handleRename(item.thread_id));
                      setDropdownOpen(null);
                    }}
                  >
                    Rename
                  </button>

                  <button
                    className="block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 w-full text-left"
                    onClick={(e) =>
                      handleButtonClick(e, () => handleDelete(item.thread_id))
                    }
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>

      <div className="mt-1">
        <div className="text-sm text-center pb-4">
          {userLevel === "free" && (
            <>
              <p className="mb-1">Not satisfied with the answers?</p>
              <p
                className="text-green-500 cursor-pointer hover:underline"
                onClick={openModal}
              >
                Get Premium now
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
