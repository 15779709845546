import React, { useState, useEffect, useRef } from "react";
import { FaPaperclip, FaInfoCircle, FaTimes } from "react-icons/fa";
import { encode, isWithinTokenLimit } from "gpt-tokenizer";
import api from "../../../utils/api";
import { useAuth } from "../../../utils/AuthProvider";

const MessageInput = ({
  newMessage,
  setNewMessage,
  handleSendMessage,
  handleFileChange,
  fileInputRef,
  handleUpload,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tokenCount, setTokenCount] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [wordCount, setWordCount] = useState(0);

  const { token } = useAuth();
  const userLevel = JSON.parse(sessionStorage.getItem("userLevel"));
  const textRef = useRef(null);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
    textRef.current.style.height = "auto";
    textRef.current.style.height = `${Math.min(
      textRef.current.scrollHeight,
      125
    )}px`;
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      handleFileChange(event);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };

  const onSendClick = () => {
    if (newMessage.trim() === "" && !selectedFile) return;
    handleSendMessage(newMessage, selectedFile, "user", tokenCount);
    setSelectedFile(null);
    fileInputRef.current.value = null;
    setNewMessage("");
  };

  const updateTokenCount = (message) => {
    const tokens = encode(message);
    setTokenCount(tokens.length);
  };

  useEffect(() => {
    updateTokenCount(newMessage);
  }, [newMessage]);

  useEffect(() => {
    const fetchWordCount = async () => {
      try {
        const response = await api.get(`/chat/token`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWordCount(response.data?.chat_token);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    fetchWordCount();
  });
  // console.warn("hahah", wordCount);
  const isSendButtonDisabled = userLevel == "free" && wordCount > 5000;
  // console.log(isSendButtonDisabled);
  return (
    <div className="pt-4 px-4 bg-gradient-to-r from-blue-50 to-green-50 border-t border-gray-200 rounded-lg shadow-md">
      <div className="flex flex-col sm:flex-row items-center">
        <div className="relative w-full sm:max-w-auto">
          <textarea
            ref={textRef}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400 resize-none text-sm pr-10 shadow-inner bg-white placeholder-gray-500"
            placeholder="Type your message here... 💬"
            value={newMessage}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (!isSendButtonDisabled && newMessage !== null) {
                  e.preventDefault();
                  onSendClick();
                } else {
                  e.preventDefault();
                }
              }
            }}
            rows="1"
            style={{ maxHeight: "125px", overflow: "auto", height: "auto" }}
          />

          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={onFileChange}
          />
          <button
            type="button"
            className="absolute inset-y-0 right-3 sm:right-5 pb-1 flex items-center text-gray-500 hover:text-green-500"
            onClick={handleUpload}
            onMouseEnter={() =>
              userLevel !== "premium" &&
              userLevel !== "employee" &&
              setShowTooltip(true)
            }
            onMouseLeave={() => setShowTooltip(false)}
          >
            <FaPaperclip size={18} />
          </button>

          {(userLevel !== "premium" || userLevel !== "employee") &&
            showTooltip && (
              <div className="absolute bottom-10 right-2 sm:right-4 bg-gray-700 text-white text-xs p-2 rounded-md shadow-md">
                This feature is available for premium users only
              </div>
            )}
        </div>

        <button
          className="px-2 ml-2 bg-green-500 hover:bg-green-600 text-white rounded shadow w-full sm:mb-0 sm:w-auto min-h-10 text-sm"
          onClick={onSendClick}
          disabled={isSendButtonDisabled}
        >
          Send
        </button>
      </div>

      {selectedFile && (
        <div className="flex items-center mt-3 text-sm text-gray-700 bg-gray-100 p-2 rounded-lg shadow-inner">
          <p className="mr-2">📁 {selectedFile.name}</p>
          <FaTimes
            className="text-red-500 cursor-pointer hover:text-red-600"
            onClick={removeFile}
          />
        </div>
      )}

      <div className="my-1 flex justify-between items-center">
        <p className="text-center text-xs flex-1 text-gray-600">
          Get better responses by being specific and clear in your queries.
        </p>
        <FaInfoCircle
          className="ml-2 text-gray-500 hover:text-green-500 cursor-pointer"
          onMouseEnter={() => setShowInfo(true)}
          onMouseLeave={() => setShowInfo(false)}
        />
        {showInfo && (
          <div className="absolute bottom-10 right-[2%] bg-white border border-gray-300 rounded-md p-4 shadow-lg w-72 sm:w-96 text-xs z-10 transition-transform duration-200 ease-in-out transform translate-y-2">
            <div className="space-y-3 text-gray-700">
              <div>
                <strong>Goal:</strong> Clearly state what you want to achieve
                with your prompts.
                <p className="text-gray-600 italic">
                  Example: "I need help with financial ratios for my quarterly
                  report."
                </p>
              </div>
              <div>
                <strong>Context:</strong> Provide background information to help
                the bot understand your needs.
                <p className="text-gray-600 italic">
                  Example: "I'm preparing taxes for a small business using
                  cash-basis accounting."
                </p>
              </div>
              <div>
                <strong>Instruction:</strong> Include details or data to make
                the response accurate.
                <p className="text-gray-600 italic">
                  Example: "Provide citations and legal references related to
                  business taxation in the Philippines."
                </p>
              </div>
              <div>
                <strong>Presentation:</strong> Organize your input clearly to
                avoid confusion.
                <p className="text-gray-600 italic">
                  Example: "I'm preparing for an audit and need help with:
                  <ul className="list-decimal pl-3">
                    <li>
                      Reviewing accounts receivable balances for accuracy.
                    </li>
                    <li>Verifying the completeness of expense reports.</li>
                    <li>
                      Ensuring proper classification of capital and operational
                      expenses. Can you provide guidance on these areas?
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageInput;
