import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the app element for accessibility

export default function PrivacyPolicyModal({ modalIsOpen, closeModal }) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Privacy Policy"
      className="fixed inset-0 flex items-center justify-center p-4 z-50" // Add z-50 here
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40" // Add z-40 here
    >
      <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full p-4 overflow-y-auto max-h-screen relative">
      <button
        onClick={closeModal}
        className="absolute top-4 right-4 mt-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
        <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
            ></path>
        </svg>
        </button>
        <div className="privacy-content">
          <img src="/babylon.png" alt="Logo" className="h-16 mb-4 mr-4 sm:mr-6 lg:mr-8" />
          <h1 className="text-3xl font-bold mb-2">Privacy Policy</h1>
          <div className="privacy-content">
            <p className="mb-4"><strong>Privacy Statement</strong></p>
            <p className="mb-4"><strong>Introduction</strong></p>
            <p className="mb-4">Integrity, Transparency, Independence and Confidentiality are among the many core values and pillars of our Profession and of Babylon2k.org (“Babylon” or the “Firm”). The Firm has always been dedicated in protecting the confidentiality of sensitive information. Being in a highly regulated industry, BABYLON is committed to comply with laws and regulations among which are Data Privacy Act of 2012, its IRR, NPCs Advisories, Circulars and Memoranda to protect individual’s (“data subjects”) privacy rights.</p>
            <p className="mb-4">To learn more about the types of personal information that we collect, how we collect, use, process and update, protect, share and dispose, you may refer to our Privacy Statement. We highly encourage you to provide us any feedback on our privacy statement and notices.</p>
            <p className="mb-4"><strong>Collection and Use of Data in General</strong></p>
            <p className="mb-4">We obtain and use personal data when a natural or juridical person (as applicable):</p>
            <ul className="mb-4 list-disc list-inside">
              <li>submits an online inquiry or an online career application;</li>
              <li>undergoes recruitment process;</li>
              <li>becomes an employee, advisor, consultant, agent, supplier, or service provider of the Firm;</li>
              <li>offers products and services, sends proposals, requests, forms or other documents to the Firm;</li>
              <li>subscribes to our marketing, announcements and advisories;</li>
              <li>requests for proposals/quotations, for presentation, or for communication purposes;</li>
              <li>provides data during our seminar, networking or marketing events;</li>
              <li>becomes our client;</li>
              <li>provides us with personal data directly or indirectly to execute our contract or agreements, as part of legitimate business protocols and objectives;</li>
              <li>provides us with personal data directly or indirectly through other natural or juridical persons;</li>
              <li>has publicly made the personal data available; and</li>
              <li>participates in surveys, research, data gathering activities and company profiling.</li>
            </ul>
            <p className="mb-4">Providing us with your personal data through the above-mentioned circumstances shall mean that you agree with our Privacy Policy. This supplements previously and subsequently provided consent obtained for a specific purpose.</p>
            <p className="mb-4"><strong>Online Inquiry and Online Career Application</strong></p>
            <p className="mb-4">In general, we collect personal data that is voluntarily submitted thru online by individuals. This is either thru online inquiry at http://www.babylon2k.org/ or thru online career application sent to ask@babylon2k.org. By voluntary submission of information, you are expressly agreeing to policies set forth in Privacy Statement. We only collect personal data that are relevant to fulfill its purpose.</p>
            <p className="mb-4">For the online inquiry, only Name, Email and Phone number are collected. These are necessarily utilized only for the purpose of answering individual’s queries. Should there be a need for additional personal information, we shall request from you the necessary information at the point of collection. And for the online career application, this shall be utilized for Babylon’s’ recruitment and employment purposes only.</p>
            <p className="mb-4">The information obtained shall not be used for any other purposes unless we obtain consent from you or otherwise required or permitted by law or regulation or professional standards.</p>
            <p className="mb-4"><strong>Recruitment and Employment Process (For Employees & Specialists)</strong></p>
            <p className="mb-4">We obtain personal data from resumes submitted through voluntary online career application or thru referrals both from current and prospective employees/ specialists to initiate the employment/contracting application process. The collected personal data of any applicant, whether hired or not shall be stored in our database for two (2) years. Personal data of applicants/specialists who were not hired are retained for future selection process. For hired employees/ specialists, a contract shall be consummated after the interview. Resumes of those successfully employed/ contracted are stored in their respective 201 or equivalent files. In addition to personal data reflected on resumes, we request our new employees/ specialists to provide for their photos, medical certificates and government IDs (SSS, TIN, Philhealth, Pag-ibig and PRC IDs, if any). Only authorized personnel have access to their related files. Personal data are collected, used and processed to initiate, carry out and terminate employment/contracting agreement.</p>
            <p className="mb-4">We share their personal data other than in the context of an employer-employee relationship when expressly authorized by law or when an applicant or an employee has given his/her consent or when the Firm’s name is given as reference. All personnel are duty bound to observe confidentiality.</p>
            <p className="mb-4"><strong>Advisories / Announcement / Marketing thru E-mail</strong></p>
            <p className="mb-4">We shall ask your permission to provide personal data such as email address, name and company or affiliation, in order for you to receive tax and other regulatory advisories including upcoming events, services and seminars through e-mail which you are free to agree or decline. Any personal data will be kept on file by BABYLON’S and shall be used for introduction to certain range of BABYLON’s services as well. Our list of clients’ email addresses is password protected to prevent unsolicited emails from being sent to people on the list and details will not be passed on to unauthorized third parties. A request to remove your respective email address from our list at any time can be made by following the instruction included in each communication or simply replying back to the email sender such as ask@babylon2k.org or writing to Babylon, U1805 Cityland Pasong Tamo Tower 2210 Chino Roces Ave., Makati City, 1231 Philippines and stating which lists and /or newsletters you wish to be removed from.</p>
            <p className="mb-4"><strong>Sharing</strong></p>
            <p className="mb-4">Personal data collected shall be accessed and used by BABYLON’s authorized employees, specialists, and consultants. We do not share information with unaffiliated third parties except when required or permitted by law or professional standards or are necessary to execute your requests, for declared, specified and legitimate purposes, business and professional needs.</p>
            <p className="mb-4">In certain instances, in order to achieve the purpose, BABYLON may share your personal data to other service providers or companies within the Philippines or across geographical borders even with other UHY International member firms which are working with us or working on our behalf. Information shared shall be on a need-to-know basis only. We shall require third parties to enter into confidentiality or nondisclosure agreements. We shall be obliged to disclose confidential information if the law and profession explicitly require us to do so.</p>
            <p className="mb-4">BABYLON shall neither sell nor transfer your personal data to any third parties to be used for direct marketing.</p>
            <p className="mb-4"><strong>Retention and Disposal</strong></p>
            <p className="mb-4">We shall retain the personal data collected for a period not longer than necessary. Necessary means for the fulfilment of the declared, specified and legitimate purpose or when processing relevant to the purpose has been terminated; for the establishment, exercise or defense of legal claims, which must be consistent with standards followed by the industry or approved by appropriate government agency or as provided by law. In some cases, personal data shall be stored in a database for at least 5 to 10 years after complaints, inquiries, requests and employment have been terminated or resolved.</p>
            <p className="mb-4">Information collected from clients for the purpose of advisories, announcements and marketing thru email, related personal data shall be deleted upon notice of non-subscription. For every communication, a notice to the recipients shall be sent allowing them to unsubscribe.</p>
            <p className="mb-4">We also have policies to ensure personal data is disposed or discarded in a secure manner that would prevent further processing, unauthorized access, or disclosure to any other party or the public, which shall prejudice the interests of the data subjects. We also take reasonable steps to destroy or de-identify personal data if it is no longer needed for any purpose. We shred hard copies or permanently delete digital files or anonymized as deemed appropriate.</p>
            <p className="mb-4"><strong>Supplemental</strong></p>
            <p className="mb-4">By communicating with Babylon, using Babylon services, purchasing products and services from Babylon or by virtue of your engagement with Babylon, you acknowledge that you have read and understood this Policy and agree and consent to the use, processing and transfer of your Personal Data by Babylon as described in this Policy.</p>
            <p className="mb-4">Babylon shall have the right to modify, update or amend the terms of this Policy at any time by placing the updated Policy on the Websites. By continuing to communicate with Babylon, by continuing to use Babylon’s services, purchasing products/ services from Babylon or by your continued engagement with Babylon following the modifications, updates or amendments to this Policy, such actions shall signify your acceptance of such modifications, updates or amendments.</p>
            <p className="mb-4"><strong>Personal Data Privacy Security Measures and Breach Management</strong></p>
            <p className="mb-4">Our Profession is bound on confidentiality unless required or authorized by law and professional standards. BABYLON implements reasonable and appropriate organizational, physical and technical measures intended for the protection of personal information against any accidental and unlawful destruction, alteration and disclosure, misuse and against any other unlawful processing. These measures include periodic internal reviews of our personal data collection, use, storage, retention, processing and disposal policies and practices. We provide access of personal data to authorized personnel only and observe physical security measures as well.</p>
            <p className="mb-4">Despite BABYLON’s best efforts, we could not absolutely guarantee the security against all risks or threats. We have exerted reasonable efforts to limit the access of personal data to those authorized to know. Individuals who were given access primarily our employees/ specialists/ contractors are bound to maintain confidentiality through their non-disclosure agreements.</p>
            <p className="mb-4">We shall comply with the relevant provisions of Data Privacy Act of 2012, its IRR, NPCs circulars and advisories on handling personal data security breaches. Within forty-eight (48) hours from discovery, we shall notify the data subject or the involved party that there is an unauthorized use or disclosure of data. We shall require the same from third or external parties.</p>
            <p className="mb-4"><strong>Data Subjects’ Privacy Rights</strong></p>
            <p className="mb-4">BABYLON highly values and protects your privacy rights relative to your personal data. Under most circumstances, you may exercise your right to access, correct any inaccuracy, update or remove your personal data. You may refer to our concerns and feedback for the contact details of our DPO. We shall be amenable within reasonable means to fulfill your requests within the bounds of law, moral or public policy.</p>
            <p className="mb-4"><strong>Periodic Review and Amendment</strong></p>
            <p className="mb-4">BABYLON periodically reviews and if it warrants, modifies its privacy statement to comply with the recent privacy regulations and practices. Any changes shall be incorporated in the statement. When changes are incorporated, we shall also revise the “Last Update Date” portion. As we update it from time to time, you agree to be bound by the prevailing terms of this policy. Thus, we encourage you to take initiative in revisiting our Privacy Statement periodically.</p>
            <p className="mb-4"><strong>Concerns and Feedback</strong></p>
            <p className="mb-4">Should you have questions or feedback or concerns about our privacy statements, our compliance and management of your personal information, feel free to contact our DPO at ask@babylon2k.org. In the event of any conflict between the English and other language versions, the English version shall prevail.</p>
          </div>
          <button
            onClick={closeModal}
            className="mt-6 w-full bg-indigo-600 text-white py-2 rounded-lg font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}