import api from "../../utils/api";
import { toast } from "react-toastify";

export default function ProfileTab({ setActiveSection, logout, token, user }) {
  const userLevel = JSON.parse(sessionStorage.getItem("userLevel")).replace(
    /^\w/,
    (c) => c.toUpperCase()
  );

  const handleCancel = async () => {
    try {
      await api.post(
        `/payment/deactivate/subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Subscription canceled successfully");
    } catch (error) {
      // Handle different error responses based on status code
      if (error.response.status === 403) {
        toast.error(
          error.response.data.detail || "You are already on the free plan"
        );
      } else {
        toast.error(
          error.response.data?.message || "Error canceling subscription"
        );
      }
    }
  };

  const handleChangePassword = async () => {
    try {
      const response = await api.post(
        `/email/forget-password?email=${user.email}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        toast.error("Error sending email to reset password");
      }
      toast.success("We have sent you an email to reset your password");
    } catch (error) {
      toast.error("Error sending email to reset password");
    }
  };

  return (
    <div className="space-y-6">
      <section>
        <div className="border border-gray-200 shadow-lg p-6 rounded-lg bg-white">
          <h3 className="text-2xl font-bold mb-4 border-b border-gray-300 pb-2">
            User Information
          </h3>
          <div className="space-y-2">
            <p>
              <span className="font-semibold">Email: </span>
              <span className="break-words">{user.email}</span>
            </p>

            <p>
              <span className="font-semibold">Current Password:</span> ********
            </p>
            <p>
              <span className="font-semibold">Plan Type:</span> {userLevel}
            </p>
            {/* <p>
              <span className="font-semibold">Next Billing Date:</span>{" "}
              February 27, 2024
            </p> */}
          </div>
          <div className="flex flex-col sm:flex-row sm:space-x-4 mt-6">
            <button
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-all shadow-lg sm:mt-0"
              onClick={handleChangePassword}
            >
              Change Password
            </button>
            <button
              onClick={handleCancel}
              className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded mt-4 sm:mt-0 transition-all shadow-lg"
            >
              Cancel Plan
            </button>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="border border-gray-200 shadow-lg p-6 rounded-lg bg-white">
          <h3 className="text-2xl font-bold mb-4 border-b border-gray-300 pb-2">
            Plan Details
          </h3>
          <div className="space-y-2">
            
            <p>
              <span className="font-semibold">Subscription Start Date:</span>{" "}
              January 27, 2024
            </p>
            
          </div>
          <div className="flex flex-col sm:flex-row sm:space-x-4 mt-6">
            {/* <button
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-all shadow-lg"
              onClick={() => setActiveSection("Payments")}
            >
              View Payment History
            </button> */}

      {/* </div> */}
      {/* //   </div> */}
      {/* // </section> */}
    </div>
  );
}
