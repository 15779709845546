import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation as useReactLocation } from "react-router-dom"; // Import useLocation with a different name
import api from "../../utils/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InputField = React.forwardRef(
  (
    {
      type,
      placeholder,
      value,
      onChange,
      onPaste,
      className,
      maxLength,
      onKeyUp,
      onFocus,
    },
    ref
  ) => (
    <input
      type={type}
      placeholder={placeholder}
      className={`mb-4 p-2 w-full border border-gray-300 rounded focus:outline-none focus:border-green-500 ${className}`}
      value={value}
      onChange={onChange}
      onPaste={onPaste}
      maxLength={maxLength}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      ref={ref}
    />
  )
);

const EmailActivation = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [additionalInputs, setAdditionalInputs] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const navigate = useNavigate();
  const locationData = useReactLocation(); // Use a different name for useLocation
  const userEmail = locationData.state?.userEmail || "";

  useEffect(() => {
    sessionStorage.setItem("email", userEmail);
  }, [userEmail]);

  const otpData = additionalInputs.join("");

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSend = async () => {
    try {
      await api.post(
        `/otp/verify`,
        {
          email: sessionStorage.getItem("email"),
          otp: otpData,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success("Account verified");
      setTimeout(() => {
        navigate("/signin");
      }, 1500);
    } catch (error) {
      console.error("Error sending OTP:", error.response || error);
      toast.error(error.response?.data?.detail || error.message);
    }
  };

  const handleResend = async () => {
    const email = sessionStorage.getItem("email");
    try {
      await api.post(`/otp/resend?email=${email}`, {
        headers: { "Content-Type": "application/json" },
      });
      toast.success("Email resent");
      setAdditionalInputs(["", "", "", "", "", ""]);
    } catch (error) {
      toast.error(error.response?.data?.detail || error.message);
      setTimeout(() => {
        navigate("/signup");
      }, 1500);
    }
  };

  const inputRefs = useRef([]);

  const handleAdditionalInputChange = (value, index) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    const updatedInputs = [...additionalInputs];
    updatedInputs[index] = numericValue;

    setAdditionalInputs(updatedInputs);
    if (numericValue.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData
      .getData("text")
      .slice(0, additionalInputs.length);
    const updatedInputs = pasteData.split("");
    setAdditionalInputs((prevInputs) =>
      prevInputs.map((input, index) => updatedInputs[index] || input)
    );
  };

  return (
    <div className="bg-gray-100 text-black min-h-screen flex flex-col items-center justify-center">
      <ToastContainer />
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Activate Your Account
        </h1>
        <p className="mb-4 text-center">
          Enter the OTP that has been sent to your email<b> {userEmail}</b> for
          account activation.
        </p>
        <p className="text-center text-sm pb-4">
          <b>
            If you don't see it in your inbox, kindly check your spam folder
          </b>
        </p>

        <div className="flex">
          {additionalInputs.map((inputValue, index) => (
            <InputField
              key={index}
              type="text"
              value={inputValue}
              onChange={(e) =>
                handleAdditionalInputChange(e.target.value, index)
              }
              onPaste={handlePaste}
              className="mb-4 mr-2 px-2 py-2 w-1/4 text-center"
              maxLength={1}
              onFocus={() => console.log("Focused on input", index)}
              onKeyUp={(e) => {
                if (e.key === "Enter" && index < inputRefs.current.length - 1) {
                  inputRefs.current[index + 1].focus();
                }
              }}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>

        {showInput && (
          <InputField
            type="text"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}

        {showOtpInput && (
          <InputField
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        )}

        <div className="flex flex-col w-full items-center">
          {!showInput && (
            <>
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleSend}
              >
                Verify OTP
              </button>
              <p
                className="mt-2 text-sm text-blue-500 cursor-pointer"
                onClick={handleResend}
              >
                Resend email
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailActivation;
