import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

const clientId = '66725119123-6smk18llnv4l9ovrceno3ug7l0iahb1e.apps.googleusercontent.com';

export const GoogleAuthProviderWrapper = ({ children }) => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      {children}
    </GoogleOAuthProvider>
  );
};
