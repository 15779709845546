import React, { useState, useEffect, useCallback, useRef } from "react";
import api from "../../utils/api";
import { useAuth } from "../../utils/AuthProvider";
import Payments from "./Payments";
import Profile from "./Profile";
import SideBar from "../../components/SideBar/SideBar";
import Spinner from "../../components/Spinner/Spinner";
import Header from "./components/HeaderUserdashboard";
import MessageList from "./components/MessageList";
import PredefinedQuestions from "./components/PredefinedQuestions";
import MessageInput from "./components/MessageInput";
import { toast } from "react-toastify";
import { encode } from "gpt-tokenizer";

export default function UserDashboard() {
  const { logout, token } = useAuth();
  const [threadId, setThreadId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenProfile, setModalOpenProfile] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const fetchMessagesRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const userLevel = JSON.parse(sessionStorage.getItem("userLevel"));

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const closeModalProf = () => setModalOpenProfile(false);

  const predefinedQuestions = [
    {
      question:
        "How can I minimize my tax liability, and what deductions or credits am I eligible for?",
      tokenCount: 18,
    },
    {
      question:
        "How can I ensure I have adequate financial reserves to handle unexpected expenses or economic downturns?",
      tokenCount: 18,
    },
    {
      question:
        "What types of insurance should my business have to protect against risks?",
      tokenCount: 13,
    },
    {
      question:
        "What are the requirements for filing taxes in the Philippines?",
      tokenCount: 11,
    },
    {
      question:
        "What are the penalties for non-compliance with tax regulations in the Philippines?",
      tokenCount: 15,
    },
    {
      question:
        "What are the legal requirements for registering a business in the Philippines?",
      tokenCount: 13,
    },
  ];

  const fetchThreadMessages = useCallback(
    async (threadId) => {
      setLoading(true);
      try {
        const response = await api.get(`/chat/history/thread/${threadId}`);
        const fetchedMessages = response.data;
        if (fetchedMessages && fetchedMessages.length > 0) {
          setMessages((prevMessages) => {
            const newMessages = fetchedMessages.filter(
              (msg) =>
                !prevMessages.some((existingMsg) => existingMsg.id === msg.id)
            );
            const updatedMessages = [...prevMessages, ...newMessages];
            // localStorage.setItem(`messages_${threadId}`, JSON.stringify(updatedMessages));
            return updatedMessages;
          });
        } else {
          console.warn("No messages found for the thread.");
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    },
    [threadId]
  );

  useEffect(() => {
    const fetchHistory = async (retryCount = 3) => {
      setLoading(true);
      try {
        const response = await api.get(`/chat/history/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 500) {
          throw new Error("Internal Server Error");
        }
        console.warn(response.data);
        const fetchedHistory = response.data;
        if (fetchedHistory && fetchedHistory.length > 0) {
          setThreadId(fetchedHistory[0].thread_id);
          sessionStorage.setItem("threadId", fetchedHistory[0].thread_id);
        } else {
          console.warn("No threads found.");
          const response = await api.get(`/chat/new/thread`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setThreadId(response.data.thread_id);
          sessionStorage.setItem(
            "threadId",
            response.data.thread_id.toString()
          );
          setRefresh(!refresh);
        }
      } catch (error) {
        console.error("Error getting history:", error);

        if (error.response && error.response.status === 500 && retryCount > 0) {
          console.warn(`Retrying... ${retryCount} attempts left.`);
          setTimeout(() => fetchHistory(retryCount - 1), 3000);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              role: "system",
              text_value:
                "An error occurred while fetching the chat history. Please try again later.",
            },
          ]);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [token]);

  const handleSendMessage = async (
    message,
    file,
    sender = "user",
    tokenCount
  ) => {
    const trimmedMessage = message.trim();
    const selectedFile = fileInputRef.current.files[0];
    // if (!trimmedMessage) return;

    setNewMessage("");
    setHasError(false);

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: sender,
        text_value: trimmedMessage,
        file: file ? file.name : null,
      },
      { role: "assistant", text_value: "" },
    ]);

    setIsLoading(true);

    let formData = new FormData();
    formData.append("thread_id", threadId);
    formData.append("message", trimmedMessage);

    if (file) {
      formData.append("file", selectedFile);
    }
    console.log(file);
    try {
      await api.post(`/chat/response/assistant`, formData);
      const eventSource = new EventSource(
        `https://api.babylon2k.org/chat/stream/v2?thread_id=${threadId}&token=${token}`
      );

      eventSource.onopen = async () => {
        const response = await api.post(
          `/chat/update/token?chat_token=${tokenCount}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      };

      let accumulatedText = "";
      let isFirstResponse = true;

      eventSource.onmessage = (event) => {
        const eventData = JSON.parse(event.data);

        console.warn(eventData);
        if (eventData.event === "text_created") {
          if (isFirstResponse) {
            isFirstResponse = false;

            accumulatedText = "";
          } else {
            accumulatedText = eventData.data;
          }
        } else if (eventData.event === "text_delta") {
          if (!isFirstResponse) {
            accumulatedText += eventData.data;
          }
        }

        const cleanedText = accumulatedText.replace(/【[^】]*】/g, "").trim();

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1].text_value = cleanedText;
          console.log(updatedMessages);
          return updatedMessages;
        });

        if (eventData.event === "text_created") {
          setIsLoading(false);
        }
        //console.log("Message received:", eventData.data, "timestamp:", new Date().toLocaleTimeString());
      };

      eventSource.onerror = () => {
        eventSource.close();
        fetchThreadMessages(threadId);
        setIsLoading(false);
        setHasError(true);
      };

      return () => {
        eventSource.close();
        // console.log("Event source closed in cleanup.");
      };
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
    }
  };
  const handleRegenerate = () => {
    const lastMessage = messages[messages.length - 2]?.text_value || "";
    const tokenCount = encode(lastMessage).length;
    const lastFile = fileInputRef.current?.files[0] || null;
    handleSendMessage(lastMessage, lastFile, "user", tokenCount);
  };

  const handlePredefinedQuestionClick = (question) => {
    const tokenCount = encode(question).length;
    handleSendMessage(question, null, "user", tokenCount);
  };

  useEffect(() => {
    if (threadId) {
      fetchThreadMessages(threadId);
    }
  }, [threadId, fetchThreadMessages]);

  const handleUpload = () => {
    if (userLevel === "premium" || userLevel === "employee") {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file.name);
    }
  };

  return (
    <>
      <div className="flex h-screen text-black">
        {/* Sidebar */}
        <div
          className={`flex flex-col h-full ${
            sidebarVisible ? "sm:w-64" : "w-0"
          } sm:transition-width sm:duration-500`}
        >
          <div
            className={`h-full sm:w-64 bg-white transform transition-transform duration-500 z-10 ${
              sidebarVisible ? "translate-x-0" : "-translate-x-full"
            } sm:relative fixed`}
          >
            <div className="flex flex-col h-full overflow-y-auto">
              {sidebarVisible && (
                <SideBar
                  key={refresh}
                  token={token}
                  threadId={threadId}
                  setThreadId={setThreadId}
                  setMessages={setMessages}
                  sidebarVisible={sidebarVisible}
                  setSidebarVisible={setSidebarVisible}
                  openModal={openModal}
                  closeModal={closeModal}
                  setModalOpenProfile={setModalOpenProfile}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-grow min-h-screen transition-all duration-500">
          <Header
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            logout={logout}
            openModal={openModal}
          />
          <div className="flex flex-col flex-grow">
            <div
              className="flex-grow p-2 overflow-y-auto border-r border-gray-300"
              style={{ height: "calc(80vh - 64px)" }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <MessageList
                    messages={messages}
                    isLoading={isLoading}
                    messagesEndRef={messagesEndRef}
                    hasError={hasError}
                    handleRegenerate={handleRegenerate}
                  />
                  {messages.length === 0 && !loading && (
                    <PredefinedQuestions
                      predefinedQuestions={predefinedQuestions}
                      handlePredefinedQuestionClick={
                        handlePredefinedQuestionClick
                      }
                    />
                  )}
                </>
              )}
            </div>
            <MessageInput
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              handleSendMessage={handleSendMessage}
              handleFileChange={handleFileChange}
              fileInputRef={fileInputRef}
              handleUpload={handleUpload}
            />
          </div>
          <Payments modalOpen={modalOpen} closeModal={closeModal} />
        </div>
        <Profile
          modalOpen={modalOpenProfile}
          closeModal={closeModalProf}
          logout={logout}
          user={userLevel}
        />
      </div>
    </>
  );
}
