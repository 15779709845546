import React, { useState } from 'react';
import api from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ContactEmail() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',             
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadingToast = toast.loading('Sending email...');
    try {
      await api.post('/email/contact', formData);
      toast.update(loadingToast, {
        render: 'Email sent successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      });
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      toast.update(loadingToast, {
        render: 'Failed to send email',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  return (
    <section className="relative z-0 mt-16 bg-gray-100 py-12 rounded-lg shadow-lg">
      <div className="max-w-4xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl  font-bold mb-4">Got Questions?</h2>
        <p className="mb-6 text-lg">Ask specific questions from our customer support</p>
        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
          <div className="col-span-1">
            <label className="block text-left text-lg font-medium mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-span-1">
            <label className="block text-left text-lg font-medium mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-span-1">
            <label className="block text-left text-lg font-medium mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
              id="message"
              name="message"
              rows="6"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="col-span-1 text-center">
            <button
              className="w-full md:w-auto bg-green-600 text-white text-lg px-8 py-3 rounded-lg hover:bg-green-700 transition-colors duration-300"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </section>
  );
}
