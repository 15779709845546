import React from "react";
import { FaCheckCircle } from "react-icons/fa";

export default function PaymentSuccess() {
    return (
        <div className="flex justify-center items-center h-screen bg-gradient-to-r from-green-200 to-green-500">
            <div className="bg-white shadow-lg rounded-lg p-6 text-center max-w-sm">
                <div className="flex justify-center mb-4">
                    <FaCheckCircle className="text-green-500 text-6xl" />
                </div>
                <h1 className="text-3xl font-bold text-gray-800 mb-2">Payment Successful!</h1>
                <p className="text-gray-600 mb-6">Your payment was processed successfully.</p>
                <button
                    onClick={() => window.location.href = "/app"}
                    className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
                >
                    Go to Dashboard
                </button>
            </div>
        </div>
    );
}
