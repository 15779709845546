import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../../components/NavBar/NavBar';

export default function AdminDashboard() {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    if (file) {
      setUploading(true);
      // Simulate file upload
      setTimeout(() => {
        setFiles([...files, file]);
        setFile(null);
        setUploading(false);
      }, 1000);
    } else {
      setError('Please select a file to upload');
    }
  };

  return (
    <div className="bg-gray-50 text-black min-h-screen">

      <main className="container mx-auto py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">Admin Dashboard</h1>
        <section className="flex flex-col items-center">
          <form onSubmit={handleFileUpload} className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg mb-8 border border-gray-300">
            <h2 className="text-2xl font-bold mb-4">Upload Knowledge Base File</h2>
            <div className="mb-4">
              <label htmlFor="file" className="block text-sm font-medium text-gray-700">Select File</label>
              <input
                type="file"
                id="file"
                onChange={handleFileChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              />
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <button
              type="submit"
              className="w-full bg-primary text-black px-4 py-2 rounded-lg hover:bg-primaryHover font-semibold flex items-center justify-center"
              disabled={uploading}
            >
              {uploading ? (
                <div className="flex items-center">
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2"></div>
                  Uploading...
                </div>
              ) : (
                <>
                  <FontAwesomeIcon icon={faUpload} className="mr-2" />
                  Upload File
                </>
              )}
            </button>
          </form>

          <section className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg border border-gray-300">
            <h2 className="text-2xl font-bold mb-4">Uploaded Files</h2>
            <table className="w-full text-left">
              <thead>
                <tr>
                  <th className="border-b border-gray-300 px-4 py-2">File Name</th>
                  <th className="border-b border-gray-300 px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {files.length === 0 && (
                  <tr>
                    <td colSpan="2" className="text-center py-4">
                      No files uploaded yet.
                    </td>
                  </tr>
                )}
                {files.map((file, index) => (
                  <tr key={index}>
                    <td className="border-b border-gray-300 px-4 py-2">{file.name}</td>
                    <td className="border-b border-gray-300 px-4 py-2">
                      <button
                        className="text-red-500 hover:underline"
                        onClick={() => setFiles(files.filter((_, i) => i !== index))}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </section>
      </main>
    </div>
  );
}
