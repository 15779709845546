import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Terms from '../Terms/Terms';

export default function Footer() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termsOfServiceModalIsOpen, setTermsOfServiceModalIsOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openTermsOfServiceModal = () => {
    setTermsOfServiceModalIsOpen(true);
  };

  const closeTermsOfServiceModal = () => {
    setTermsOfServiceModalIsOpen(false);
  };

  return (
    <footer className="bg-gray-700 text-white z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-8 flex flex-wrap items-center justify-center border-t border-gray-700">
          <div className="flex justify-center space-x-6 sm:order-2">
            <Link to="/about-us" onClick={scrollToTop} className="text-gray-400 hover:text-gray-300">About</Link>
            <Link onClick={openModal} className="text-gray-400 hover:text-gray-300">Privacy</Link>
            <Link onClick={openTermsOfServiceModal} className="text-gray-400 hover:text-gray-300">Terms of Use</Link>
            <Link to="/pricing" onClick={scrollToTop} className="text-gray-400 hover:text-gray-300">Pricing</Link>
          </div>
          <div className="mt-8 md:mt-0 sm:order-1 mr-0 sm:mr-16">
            <p className="text-center text-gray-400">&copy; 2024 Babylon by I-Lead. All rights reserved.</p>
          </div>
        </div>
      </div>
      <PrivacyPolicy modalIsOpen={modalIsOpen} closeModal={closeModal} />
      <Terms modalIsOpen={termsOfServiceModalIsOpen} closeModal={closeTermsOfServiceModal} /> 
    </footer>
  );
}
