import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import Navbar from "../../components/NavBar/NavBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../utils/AuthProvider";
import api from "../../utils/api";

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleMicrosoftLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["openid", "profile", "User.Read"],
      });

      const user = loginResponse.account;
      const result = await api.post(
        `/login/`,
        { username: user.username },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(result.data);
      console.log(result.data.token);
      login(result.data.token);

      toast.success("Microsoft sign in successful!");
      navigate("/app");
    } catch (error) {
      console.error("Microsoft sign in error:", error);
      toast.error("Microsoft sign in failed. Please try again.");
    }
  };

  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });

  const onChangeForm = (label, event) => {
    setLoginForm({ ...loginForm, [label]: event.target.value });
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post("/login", loginForm, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const { access_token } = response.data;
      login(access_token);

      toast.success("Login successfully!");
      setTimeout(() => {
        navigate("/app");
      }, 2500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.detail);
      } else if (error.request) {
        toast.error("No response from server. Please try again later.");
      } else {
        toast.error("Error in setting up the request");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-white text-black min-h-screen flex flex-col">
      <Navbar />
      <main className="relative flex-grow bg-cover py-12 flex justify-center items-center lg:px-8 saturate-100 bg-image-container">
        <div
          className="absolute inset-0 bg-cover bg-center filter blur-sm"
          style={{ backgroundImage: "url(pic6.jpeg)" }}
        ></div>
        <section className="flex justify-center items-center w-full relative z-10">
          <div className="w-full max-w-lg bg-gray-100 p-8 sm:p-12 rounded-lg shadow-lg">
            <h2 className="text-[#66B539] text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center">
              Sign In
            </h2>
            <form className="space-y-6 sm:space-y-8" onSubmit={handleSignIn}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm sm:text-lg font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={(event) => onChangeForm("username", event)}
                  className="mt-1 sm:mt-2 block w-full px-3 sm:px-5 py-2 sm:py-3 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary text-sm sm:text-lg"
                  required
                />
              </div>
              <div className="relative">
                <label
                  htmlFor="password"
                  className="block text-sm sm:text-lg font-medium text-gray-700"
                >
                  Password
                </label>
                <button
                  type="button"
                  className="absolute inset-y-4 right-2 px-3 sm:pt-2 flex items-center "
                  onClick={togglePasswordVisibility}
                  tabIndex={-1}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </button>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={(event) => onChangeForm("password", event)}
                  className="mt-1 sm:mt-2 block w-full px-3 sm:px-5 py-2 sm:py-3 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary text-sm sm:text-lg"
                  required
                />
                <div className="mt-2 text-xs text-right sm:text-sm">
                  <Link
                    to="/email/forgot-password"
                    className="text-red-500 hover:underline"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full bg-green-400 border-2 border-slate-300 text-black px-2 sm:px-5 py-2 sm:py-3 rounded-lg hover:bg-green-500 font-semibold text-sm sm:text-lg"
                >
                  Sign In
                </button>
              </div>
            </form>

            <div className="mt-6 sm:mt-8 flex items-center justify-center">
              <hr className="w-1/3 border-t border-gray-500" />
              <p className="text-xs sm:text-sm text-gray-600 px-1 sm:px-2">
                or login with
              </p>
              <hr className="w-1/3 border-t border-gray-500" />
            </div>
            <div className="flex flex-col justify-center sm:flex-row sm:justify-center space-y-2 sm:space-y-0 sm:space-x-4 mt-4 sm:mt-6">
              <button
                onClick={handleMicrosoftLogin}
                className="flex items-center justify-center px-3 mt-2 sm:mt-0 sm:px-5 py-2 sm:py-3 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-100 text-sm sm:text-lg"
              >
                <FontAwesomeIcon
                  icon={faMicrosoft}
                  className="h-5 sm:h-6 w-5 sm:w-6 mr-2 sm:mr-3"
                />
                <span className="text-gray-700">Microsoft</span>
              </button>
            </div>
            <p className="mt-6 sm:mt-8 text-center text-xs sm:text-lg text-gray-600">
              Don't have an account?{" "}
              <Link
                to="/signup"
                className="text-blue-500 font-medium hover:underline"
              >
                Sign Up
              </Link>
            </p>
          </div>
        </section>
      </main>
      <ToastContainer />
    </div>
  );
}
