
const Spinner = () => {
  return (
    <div className="flex justify-center items-center h-full">
        <div className="w-16 h-16 border-4 border-spacing-2 border-green-500 border-dashed rounded-full animate-spin-slow"></div>
    </div>

  );
};

export default Spinner;
