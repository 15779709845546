import React, { useState } from "react";
import Modal from "react-modal";
import api from "../../utils/api";
import PhoneInput from "react-phone-input-2";
import { useAuth } from "../../utils/AuthProvider";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import "../../styles/small_loader.css";
import { toast, ToastContainer } from "react-toastify";

export default function Payments({ modalOpen, closeModal }) {
  const [paymentModal, setPaymentModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();

  const userLevel = JSON.parse(sessionStorage.getItem("userLevel"));

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const openPaymentModal = () => {
    closeModal();
    setPaymentModal(true);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  // Check if the user is already subscribed
  const checkSubscription = async (token) => {
    try {
      const response = await api.get(`/user/level`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        if (response.data.user_level === "premium") {
          console.log("User is already subscribed to BETH Premium.");
          return true;
        }
      } else {
        console.error("An error occurred. Please try again later.");
      }
    } catch (error) {
      console.log(error);
      console.error("An error occurred. Please try again later.");
    }
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const phone = document
      .getElementById("phoneNumber")
      .value.replace(/\s+/g, "");

    const createCustomerRequest = {
      token: token,
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
    };

    try {
      const isSubscribed = await checkSubscription(token);
      if (isSubscribed === true) {
        toast.warning("You are already subscribed to BETH Premium.");
        return;
      } else {
        setIsButtonDisabled(true);
        setLoading(true);
        const createCustomerResponse = await api.post(
          "/payment/create/customer",
          createCustomerRequest,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (createCustomerResponse.status === 200) {
          const customerId = createCustomerResponse.data.customer.id;
          sessionStorage.setItem("customerId", customerId);

          // call create subscription after creating customer
          const createSubscriptionResponse = await api.post(
            "/payment/create/plan",
            {
              customer_id: sessionStorage.getItem("customerId"),
              plan_name: "premium",
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (createSubscriptionResponse.status === 200) {
            console.log(createSubscriptionResponse.data.plan.actions[0].url);
            window.location.href =
              createSubscriptionResponse.data.plan.actions[0].url;
          } else {
            console.error("An error occurred. Please try again later.");
          }
        } else {
          console.error("An error occurred. Please try again later.");
        }
      }
    } catch (error) {
      console.log(error);
      console.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Payments"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full p-6 relative max-h-full overflow-y-auto">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="text-center mb-6">
            <h2 className="text-2xl font-bold">Upgrade Your Plan</h2>
            <p className="text-gray-600">
              Choose the plan that suits your needs.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-stretch space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="border-2 shadow-sm rounded-lg p-4">
              <h3 className="text-xl font-bold">Free</h3>
              <p className="text-2xl font-bold">$0/month</p>
              {userLevel === "free" && (
                <div className="mt-2 bg-green-100 text-green-800 font-semibold p-2 rounded-md flex items-center">
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span>Your Current Plan</span>
                </div>
              )}
              <ul className="mt-4 list-disc pl-4 text-left flex-grow">
                <li>Assistance with writing, problem solving, and more</li>
                <li>Limited access to GPT-4o mini</li>
                <li>
                  Limited access to advanced data analysis and web browsing
                </li>
              </ul>
            </div>

            {/* Premium Plan */}
            <div
              className={`border-2 shadow-sm rounded-lg p-4 transition-colors duration-300 ${
                userLevel === "premium" ? "border-green-500 bg-green-50" : ""
              }`}
            >
              <h3 className="text-xl font-bold">Premium</h3>
              <p className="text-2xl font-bold">USD $16.99/month</p>
              {userLevel == "free" ? (
                <button
                  onClick={openPaymentModal}
                  className="my-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Upgrade to Premium
                </button>
              ) : (
                <div className="mt-2 bg-green-500 text-white font-semibold p-2 rounded-md flex items-center">
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span>Your Current Plan</span>
                </div>
              )}
              <ul className="mt-2 list-disc pl-4 text-left flex-grow">
                <li>Early access to new features</li>
                <li>
                  Access to advanced data analysis, file uploads, vision, and
                  web browsing
                </li>
                <li>Comprehensive access to GPT-4</li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={paymentModal}
        onRequestClose={closePaymentModal}
        contentLabel="Payment Modal"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full relative max-h-full overflow-y-auto scrollbar-hidden">
          <button
            onClick={closePaymentModal}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <form onSubmit={handleSubscribe}>
            <div className="text-center mb-6 my-4 mt-10">
              <h2 className="text-2xl font-bold">Subscribe to BETH Premium</h2>
              <p className="text-gray-600">Billed monthly</p>
            </div>

            <div className="mt-4 mx-6">
              <h3 className="text-xl font-bold my-5">Billing Information</h3>

              <div>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 border-2 my-2 rounded"
                  required
                />

                <label htmlFor="firstName" className="mt-4">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  className="w-full p-2 border-2 my-2 rounded"
                  required
                />

                <label htmlFor="lastName" className="mt-4">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  className="w-full p-2 border-2 my-2 rounded"
                  required
                />

                <label htmlFor="phone" className="mt-4">
                  Phone
                </label>
                <PhoneInput
                  inputProps={{
                    id: "phoneNumber",
                    name: "phoneNumber",
                    placeholder: "Enter phone number",
                  }}
                  country={"ph"}
                  required
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  countryCodeEditable={false}
                  containerClass="w-full max-w-full"
                  inputClass="mb-4 block text-lg w-full max-w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
                />
              </div>
              <hr className="my-6" />
            </div>

            <h3 className="text-xl font-bold my-5 mt-4 mx-6">Plan Details</h3>
            <div className="mt-4 mx-6">
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between border-b-2">
                  <p>BETH Premium</p>
                  <p>$16.99/month</p>
                </div>
                <div className="flex justify-between border-b-2">
                  <p>Subtotal</p>
                  <p>$16.99</p>
                </div>
                <div className="flex justify-between border-b-2">
                  <p>Tax</p>
                  <p>$0.00</p>
                </div>
                <div className="flex justify-between font-bold text-xl mt-2 bg-green-200">
                  <p>Total Due</p>
                  <p className="bg-green-200">$16.99</p>
                </div>
              </div>
              <button
                type="submit"
                disabled={isButtonDisabled}
                className="my-8 w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {loading ? (
                  <Loader id="spinner" className="h-6 w-6 mx-auto" />
                ) : (
                  "Subscribe"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
}
