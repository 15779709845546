import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/NavBar/NavBar";
import Chatbot from "../../components/ChatBot/ChatBot";
import Testimonials from "../../components/Testimonials/Testimonials";
import ContactEmail from "../../components/ContactEmail/ContactEmail";
import Footer from "../../components/Footer/Footer";
import Scroll from "./Scroll";
import ReactModal from "react-modal";

export default function Home() {
  const [play, setPlay] = useState(false);
  const [fade, setFade] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const openModal = () => {
    setIsModal(true);
    console.log("open");
  };

  const closeModal = () => {
    setIsModal(false);
  };
  const handleVideoEnd = () => {
    setFade(true);
    setTimeout(() => {
      setPlay((prev) => !prev);
      setFade(false);
    }, 700);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="">
      <Navbar />
      <section className="z-10 bg-green-300 text-gray-900 w-full flex items-center justify-center px-4">
        <div className="flex flex-col sm:flex-row items-center justify-center w-full max-w-6xl ">
          <div className="w-full sm:w-3/5 h-[50vh] sm:h-[70%] pt-4 flex justify-center items-center">
            <img
              src="botmascot.gif"
              alt="Mascot Bot"
              className="w-auto h-full max-h-full object-contain"
            />
          </div>

          <div className="relative p-4 sm:p-6 w-full sm:w-1/2 max-w-lg sm:max-w-none">
            <h1 className="text-2xl sm:text-3xl text-black text-center sm:text-left font-extrabold mb-4 sm:mb-6">
              Transforming Accounting with AI Precision and Human Expertise
            </h1>
            <p className="text-xs sm:text-sm lg:text-base text-justify text-black font-extralight">
              Unleashing Intelligent Solutions for Tax, Accounting, Audit, and
              Licensing—Tailored for Accountants, Entrepreneurs, and
              Organizations Everywhere—From Bustling Cities to Remote Corners
            </p>
            <div className="flex justify-center  mt-6">
              <a
                href="#getstarted"
                className="inline-block bg-black text-center text-white font-bold text-xs sm:text-sm lg:text-base 
                px-3 sm:px-5 py-2 sm:py-3 rounded-lg hover:bg-gray-700 hover:text-white transition-colors duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("getstarted")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Get Started →
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-green-300 py-12 sm:py-16 px-4">
        <div className="flex flex-col-reverse sm:flex-row items-center mx-auto space-y-8 sm:space-y-0 sm:space-x-8">
          {/* Chatbot component on the left */}
          <div
            className="flex-1 flex justify-center sm:justify-start"
            id="getstarted"
          >
            <Chatbot className="max-w-1/2 w-full" openModal={openModal} />
          </div>

          <div className="flex-1 flex justify-center items-center text-center ">
            <p className="text-3xl font-bold leading-tight mb-4">
              Automate your work in seconds with B.E.T.H.
            </p>
          </div>
        </div>
      </section>

      <div className="bg-green-300 p-8">
        <div className="flex flex-col items-center justify-center mx-auto space-y-6">
          <div className="w-full sm:w-3/4 text-center p-2">
            <p className="text-2xl sm:text-3xl font-bold text-shadow-lg">
              Witness the Future with B.E.T.H. in Action
            </p>
          </div>
          <div className="w-full sm:w-4/5 p-2 rounded-lg">
            <video
              className={`relative z-10 w-full h-auto max-h-96 rounded-lg object-contain transition-opacity duration-700 ${
                fade ? "opacity-0" : "opacity-100"
              }`}
              src={
                play
                  ? "https://bethaibucket.s3.us-east-1.amazonaws.com/videos/landingvid2.mp4"
                  : "https://bethaibucket.s3.us-east-1.amazonaws.com/videos/BETH_Video_09042024.mp4"
              }
              muted
              controls
              autoPlay={play}
              loop={false}
              onEnded={handleVideoEnd}
            />
          </div>
        </div>
      </div>

      <div className="px-10 mt-10">
        <section className="flex flex-col lg:flex-row-reverse items-center text-center md:text-left py-8 px-4 sm:px-8 md:px-8 lg:px-12">
          <div className="w-full lg:w-2/3 flex justify-center mb-4 mx-2 lg:mb-0">
            <div className="relative w-full max-w-sm sm:max-w-md lg:max-w-lg">
              <img
                src="pic5.jpeg"
                alt="About B.E.T.H."
                className="ml-2 relative w-full h-full rounded-lg object-contain"
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl text-center lg:text-left sm:text-3xl lg:text-4xl font-bold mb-4">
              About B.E.T.H.
            </h2>
            <p className="text-sm text-center lg:text-left sm:text-md lg:text-lg mb-6">
              B.E.T.H. is the ultimate artificial intelligence platform designed
              specifically for professionals in auditing, tax, and accounting.
              Experience instant, relevant answers to all your complex financial
              and compliance questions.
            </p>
            <p className="text-sm text-center lg:text-left sm:text-md lg:text-lg mb-6">
              Join our community to pioneer the future of the auditing and
              accounting industries. With B.E.T.H., you'll stay ahead of the
              curve, leveraging AI to establish yourself as the go-to expert in
              your specialty.
            </p>
            <div className="flex lg:justify-start justify-center">
              <Link
                to="/signup"
                className="inline-block text-base sm:text-lg bg-green-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md hover:bg-green-700 transition-colors duration-300 mx-auto lg:mx-0 w-auto"
              >
                Register
              </Link>
            </div>
          </div>
        </section>
        <div className="w-full flex flex-col sm:flex-row items-center justify-between mt-4 pl-0 sm:pl-8 ">
          <Scroll />
          <div className="flex flex-col my-4 sm:my=0">
            <p className="text-center font-bold text-2xl sm:text-3xl max-w-lg">
              Where accuracy meets authoritative knowledge.
            </p>
            <p className="text-center text-sm sm:text-md max-w-lg m-2">
              Know more about our reliable sources{" "}
              <Link to="/sources">
                <a className="text-blue-500 underline">here</a>.
              </Link>
            </p>
          </div>
        </div>

        <section className="relative z-10 mt-8 py-12 px-4 bg-gray-100 rounded-lg shadow-lg">
          <div className="container mx-auto">
            <div className="text-center mb-12 sm:mb-0">
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold">
                Our Features
              </h2>
              <p className="text-lg text-gray-600  my-4">
                Discover the powerful features that make our application unique.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              <div className="flex flex-col justify-center bg-white p-4 sm:p-10 rounded-lg shadow-md hover:shadow-2xl transition-shadow duration-400">
                <div className="flex items-center justify-center mb-6">
                  <img
                    src="fast.svg"
                    alt="Feature 1"
                    className="h-16 w-16 mb-2"
                  />
                </div>
                <h3 className="text-2xl text-center font-bold">
                  Instant and relevant answers
                </h3>
              </div>
              <div className="flex-row items-center justify-center bg-white p-4 sm:p-10 rounded-lg shadow-md hover:shadow-2xl transition-shadow duration-400">
                <div className="flex items-center justify-center mb-6">
                  <img
                    src="search.svg"
                    alt="Feature 2"
                    className="h-16 w-16 mb-2"
                  />
                </div>
                <h3 className="text-2xl text-center font-bold ">
                  Advance Analysis
                </h3>
              </div>
              <div className="flex-col justify-center bg-white p-4 sm:p-10 rounded-lg shadow-md hover:shadow-2xl transition-shadow duration-400">
                <div className="flex items-center justify-center mb-6">
                  <img
                    src="clock.svg"
                    alt="Feature 3"
                    className="h-16 w-16 mb-2"
                  />
                </div>
                <h3 className="text-2xl text-center font-bold">
                  24/7 Availability
                </h3>
              </div>
            </div>
            <div className="flex justify-center mt-8">
              <Link
                to="/pricing"
                onClick={scrollToTop}
                className="w-full max-w-xs h-12 text-lg bg-green-600 text-white flex items-center justify-center px-6 py-3 rounded-md hover:bg-green-700 transition-colors duration-300"
              >
                Learn More
              </Link>
            </div>
          </div>
        </section>

        <Testimonials />
        <ContactEmail />
        {isModal && (
          <ReactModal closeModal={closeModal}>
            {/* Modal content goes here */}
            <div className="p-4">
              <h2 className="text-lg font-bold">Modal Title</h2>
              <p>This is the modal content triggered by the chatbot.</p>
              <button
                className="mt-4 p-2 bg-blue-500 text-white"
                onClick={closeModal}
              >
                Close Modal
              </button>
            </div>
          </ReactModal>
        )}
      </div>
      <Footer />
    </div>
  );
}
