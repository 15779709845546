import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <section className="h-screen flex justify-center items-center bg-gray-100">
            <div className="text-center p-8 bg-white shadow-lg rounded-lg">
                
                <h1 className="text-6xl font-bold text-red-500">404</h1>
                <h2 className="text-2xl font-semibold mt-4">Page Not Found</h2>
                <p className="mt-2 text-gray-600">The URL you are looking for does not exist or you don't have permission to access this page.</p>
                <Link to="/" className="mt-4 inline-block bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700">
                    Go back to Home
                </Link>
            </div>
        </section>
    );
}
